<template lang="html">
  <div :class="classes" id="login-container" style="overflow-x: hidden;">
    <div class="overlay">
      <div class="logo-wrapper">
        <img v-if="logo" :src="logo" class="logo" />

        <template v-else>
          <img v-if="appType === 'bpo'" width="130" :src="bpoLogo()" />

          <c-logo v-else width="130" arc-color="purple" />
        </template>
      </div>

      <register
        :app-type="appType"
        :login-type="loginType"
        :loading-button="loadingButton"
        @submit="submit"
        @closeFeedback="clearErrors"
        ref="login-desktop"
        :token="token"
        :form-errors="formErrors"
        :error="error"
      />

      <footer-section
        :text="LoginTexts.footer.text"
        :links="LoginTexts.footer.links"
      />

      <background class="background" />
    </div>
  </div>
</template>

<script>
import MediaQuery from '@mixins/MediaQuery'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as types from '@store/types'

export default {
  mixins: [ MediaQuery ],

  data: () => ({
    loadingButton: false,
    token: ''
  }),

  components: {
    Register: () => import('@components/accountancyRegister/Register'),
    Background: () => import('@components/common/Background'),
    FooterSection: () => import('@components/common/Footer')
  },

  computed: {
    ...mapGetters({
      logo: types.SETTINGS_LOGO,
      LoginTexts: types.SETTINGS_TEXTS,
      appType: types.SETTINGS_APP_TYPE,
      loginType: types.SETTINGS_LOGIN_TYPE,
      formErrors: types.ACCOUNTANCY_REGISTER_FORM_ERRORS,
      registerErrorFeedback: types.ACCOUNTANCY_REGISTER_ERROR,
      loginFeedback: types.LOGIN_FEEDBACK
    }),

    classes () {
      return ['convenia-login', {
        '-bpo': this.appType === 'bpo'
      }]
    },

    error () {
      if (!this.formErrors && (this.loginFeedback || this.loginFeedback === 'err'))
        return 'signin'
      if (this.registerErrorFeedback)
        return 'register'

      return null
    }
  },

  created () {
    const { token } = this.$route.query

    if (token) this.token = token
  },

  methods: {
    ...mapActions({
      register: types.ACCOUNTANCY_REGISTER,
      signIn: types.LOGIN_SIGN_IN

    }),
    ...mapMutations({
      clearRegisterError: types.ACCOUNTANCY_REGISTER_CLEAR_ERROR,
      clearLoginFeedback: types.LOGIN_UPDATE_FEEDBACK,
      clearFormErrors: types.ACCOUNTANCY_REGISTER_CLEAR_FORM_ERRORS
    }),

    async submit (payload) {
      this.clearErrors()
      this.loadingButton = true

      await this.register(payload)

      if (!this.formErrors && !this.registerErrorFeedback) {
        const loginPayload = {
          keep_connected: true,
          email: payload.email,
          password: payload.password
        }

        await this.signIn(loginPayload)
      }

      this.loadingButton = false
    },

    clearErrors () {
      this.clearRegisterError()
      this.clearFormErrors()
      this.clearLoginFeedback('')
    },

    bpoLogo () {
      return require('@assets/logo_sync.svg')
    }
  }
}
</script>

<style lang="scss">
.convenia-login {
  & > .overlay {
    @include hide;
    position: fixed;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    overflow-y: auto;
    z-index: -1;

    & > .logo-wrapper {
      width: fit-content;
      padding: 40px 40px 30px;

      & > .logo { height: 40px; }
    }
  }

  @include tablet (true) {
    & > .overlay {
      @include show;

      & > .background {
        & > .shape-left {
          height: 35vw;
        }

        & > .shape-right {
          right: 0;
          height: 30vw;
        }
      }
    }
  }

  &.-bpo > .overlay > .background {
    & > .shape-left > #left-shape-Page-1 {
      opacity: 1;

      #left-shape-Mask { opacity: 0.05; }
      #left-shape-Banner { opacity: 0.03; }
    }
    & > .shape-right > #right-shape-Page-1 {
      opacity: 1;

      #right-shape-Oval { opacity: 0.05; }
      #right-shape-Shape { opacity: 0.03; }
    }
  }
}
</style>
